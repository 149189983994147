.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #000;
}

.clock_content {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

.clock_content h1 {
    color: var(--white-color);
    font-size: 2rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.clock_content h3 {
    color: yellow;
    font: bold;
    font-weight: 300;
    margin-bottom: 3rem;
}

.flip-clock {
    --fcc-flip-duration: 0.5s;
    --fcc-digit-block-width: 30px;
    --fcc-digit-block-height: 60px;
    --fcc-digit-font-size: 30px;
    --fcc-digit-color: #000;
    --fcc-label-font-size: 10px;
    --fcc-label-color: var(--white-color);
    --fcc-background: var(--white-color);
    --fcc-divider-color: #000;
    --fcc-divider-height: 1px;
    --fcc-separator-size: 6px;
    --fcc-separator-color: var(--white-color);
    font-weight: 700;
    margin-bottom: 2rem;
}

.clock_content img {
    position: absolute;
    top: 10px;  /* Adjust distance from the top */
    left: 20px;  /* Adjust distance from the left */
    width: 150px; /* Adjust size as needed */
}
.clock  {
    position: absolute;
    top: 50px;  /* Adjust distance from the top */
    left: 150px;  /* Adjust distance from the left */
    width: 150px; /* Adjust size as needed */
    color: var(--white-color); /* Set default color */
    font-size: 2rem;
    text-transform: uppercase;
}
.clock::first-letter {
    color: yellow; /* Make the first letter 'F' yellow */
}
.button {
    width: 140px;
    height: 45px;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
    background-color: var(--white-color);
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.button:hover {
    background-color: var(--primary-color);
    color: var(--white-color);
    transform: translateY(-7px);
    box-shadow: 0 15px 20px rgba(46, 229, 157, 0.4);
}

@media (min-width: 900px) {
    .clock_content h1 {
        font-size: 3rem;
    }

    .clock_content h3 {
        font-size: 1.5rem;
    }

    .flip-clock {
        --fcc-digit-block-width: 80px;
        --fcc-digit-block-height: 120px;
        --fcc-digit-font-size: 60px;
        --fcc-label-font-size: 20px;
    }
}
